
.addNewItem{
    padding-top: 60px;
    padding-bottom: 60px;
}

.addNewItem-title{
    font-family: inter;
  font-size: 32px;
  line-height: 1.25;
  color: #0d121c;
  font-weight: 700;
  text-align: left;
}

.addNewItem-subtitle{
    font-family: inter;
  font-size: 14px;
  line-height: 1.25;
  color: #637587;
  font-weight: 700;
  text-align: left;
  margin-bottom: 40px;
}
.addNewItem-input{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-radius: 12px;
    padding: 15px 15px;
    border: none;
    background-color: #f7fafc;
    /* width: 480px; */
    width:50%;
    font-family: inter;
}


.addNewItem-text{
    font-family: inter;
font-size: 16px;
line-height: 1.50;
color: #0d121c;
font-weight: bold;   
text-align: left;
margin-top: 20px;
margin-bottom: 5px;
}


.addNewItem-btn-wrapper{
    display: flex;
    flex-direction: row;
    margin-bottom: 40px;
    /* justify-content: flex-end; */
  }

  .addNewItem-btn{
    font-family: inter;
    margin-top: 20px;
    border-radius: 12px;
    padding: 0px 16px;
    /* width: 120px; */
    width: 10%;
    height: 40px;
    /* background-color: #F0F2F5; */
  }