.createInst {
  padding-top: 60px;
  padding-bottom: 60px;
  display: flex;
  /* flex-direction: row; */
  justify-content: center;
  align-items: center;
  width: 800px;
}

.createInst-title {
  font-family: inter;
  font-size: 32px;
  line-height: 1.25;
  color: #0d121c;
  font-weight: 700;
  text-align: left;
}

.createInst-text {
  font-family: inter;
  font-size: 16px;
  line-height: 1.5;
  color: #0d121c;
  font-weight: bold;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 5px;
}

.title-2 {
  font-size: 20px;
  margin-top: 40px;
  margin-bottom: 0px;
}

.createInst-input {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 12px;
  padding: 15px 15px;
  border: none;
  background-color: #f7fafc;
  /* width: 480px; */
  width: 100%;
  /* height: 56px; */
}
input {
  font-family: inter;
}

.createInst-btn-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.createInst-btn {
  font-family: inter;
  border-radius: 12px;
  padding: 0px 16px;
  width: 100%;
  height: 40px;
}
.errorMsg-text{
  color: red;
}