.overview-section{
  /* margin-top: 104px;
  margin-bottom: 24px; */
  /* min-height: 100vh; */
}

.overview-cards {
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 2fr;
  gap: 24px;
}
.overview-cards .card {
  padding: 16px 24px;
  border: none;
  border-radius: 10px;
}
.cards-title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: var(--common-black-color);
  margin-top: 24px;
}
.cards-count {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.6px;
  color: var(--common-black-color);
}
