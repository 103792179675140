.addSchool {
  /* padding-top: 60px; */
  padding-bottom: 60px;
  display: flex;
  /* flex-direction: row; */
  justify-content: center;
  align-items: center;
  width: 800px;
}

.addSchool-title {
  font-family: inter;
  font-size: 32px;
  line-height: 1.25;
  color: #0d121c;
  font-weight: 700;
  text-align: left;
}

.addSchool-text {
  font-family: inter;
  font-size: 16px;
  line-height: 1.5;
  color: #0d121c;
  font-weight: 500;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 5px;
}

.addSchool-input {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 12px;
  padding: 15px 15px;
  border: none;
  background-color: #f7fafc;
  /* width: 480px; */
  width: 100%;
  /* height: 56px; */
}
.addSchool-date-time-input{
  border-radius: 12px;
  padding: 15px 15px;
  border: none;
  background-color: #f7fafc;
  width: 100%;
}
input {
  font-family: inter;
}

.form-control:focus {
  /* border-color: none; */
  /* -webkit-box-shadow: none; */
  /* box-shadow: none; */
}

.addSchool-btn-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.addSchool-btn {
  font-family: inter;
  margin-top: 20px;
  border-radius: 12px;
  padding: 0px 16px;
  /* width: 120px; */
  height: 40px;
}

.error-text {
  color: red;
  text-align: left;
  margin: 0px;
}
.poc-checkbox {
  width: 24px;
  height: 24px;
}
