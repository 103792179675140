.schools-list-section{
  /* margin-top: 104px;
  margin-bottom: 24px; */
}

.school-name {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--common-black-color);
}
.updated-date {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #4f7396;
}
.status-title {
  font-size: 16px;
  line-height: 1.5;
  color: #121417;
  font-weight: 400;
  background-color: rgba(8, 255, 18, 0.3);
  width: fit-content;
  padding: 4px 8px;
  border-radius: 12px;
}
.schools-list-section .table {
  text-align: left;
}
.schools-list-section table td{
  padding: 10px;
}