.addProduct {
  /* padding-top: 60px; */
  padding-bottom: 60px;
}
.addProduct-title {
  font-family: inter;
  font-size: 32px;
  line-height: 1.25;
  color: #0d121c;
  font-weight: 700;
  text-align: left;
}

.addProduct-text {
  font-family: inter;
  font-size: 16px;
  line-height: 1.5;
  color: #0d121c;
  font-weight: bold;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 8px;
}

.addProduct-input {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 12px;
  padding: 15px 15px;
  border: none;
  background-color: #f7fafc;
  /* width: 480px; */
  /* width:50%; */
  font-family: inter;
}

.addProduct-search {
  /* position: absolute; */
}

.dropdown-container {
  position: relative;
}

.addProduct-dropdown {
  cursor: pointer;
  position: absolute;
  width: 100%;
  z-index: 10;
  top: 6px;
  /* border: 1px solid #ccc;  */
  max-height: 200px;
  overflow-y: auto;
}

.addProduct-dropdown-options {
  margin-bottom: 6px;
  border: none;
  background-color: transparent;
  border-radius: opx;
  width: 100%;
}

.addProduct-btn-wrapper {
  display: flex;
  flex-direction: row;
  /* margin-bottom: 40px; */
  justify-content: flex-end;
}

.addProduct-btn {
  font-family: inter;
  /* margin-top: 10px; */
  border-radius: 12px;
  padding: 0px 16px;
  /* width: 120px; */
  width: 32%;
  /* height: 40px; */
  /* background-color: #F0F2F5; */
}

.addProduct-table-wrapper {
  margin-top: 20px;
  font-family: inter;
  border: 1px solid #dbe0e5;
  border-radius: 10px;
}

.addProduct-deleteBtn {
  font-family: inter;
  /* color: black; */
  /* background-color:transparent; */
  border-color: transparent;
}

.addProduct-table td {
  padding: 10px 0;
}

.addProduct-saveBtn-wrapper {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.addProduct-saveBtn-wrapper,
button {
  font-family: inter;
}

.highlighted {
  background-color: #d3d3d3;
}

.addProduct-QntyInput {
  width: 25%;
  border: 1px solid #dbe0e5;
  border-radius: 8px;
  text-align: center;
}
