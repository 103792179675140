.activities-title {
    font-family: inter;
    font-size: 32px;
    line-height: 1.25;
    color: #0d121c;
    font-weight: 700;
    text-align: left;
  }

  .activities-list-wrapper ul{
    list-style-type: none;
  }

  .activities-list-wrapper ul li:before{
    content:'\2713\0020';
  }
