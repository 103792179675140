/* .create-order-btn {
  background-color: #0d7df2;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.21px;
  color: #f7fafc;
  border-radius: 12px;
  padding: 8px 18px;
} */
.all-order-header {
  display: flex;
  justify-content: space-between;
}
/* .export-order-btn {
  background-color: #e8edf5;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #0d141c;
  border-radius: 12px;
  padding: 8px 18px;
} */
.order-status-chips p,
.order-status-chips span {
  background-color: #e8edf5;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #0d141c;
  padding: 6px 14px;
  border-radius: 12px;
  cursor: pointer;
}
.view-btn {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.21px;
  color: #4a709c;
  text-decoration: none;
}
.status-active {
  background-color: rgb(74, 117, 117);
  color: white;
}

.pagination {
  padding-top: 5px;
}

.result_msg {
  padding-left: 50px;
}

.result_msg_text {
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #5c677d;
  margin-bottom: 0;
  margin-top: 10px;
}

.paginate_msg_text {
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #5c677d;
  margin-bottom: 0;
  margin-right: 14px;
  margin-left: 16px;
}

.pagination_prev {
  width: auto;
  height: 12px;
}

.pagination_btn {
  width: 20px;
  height: 20px;
  border: 1px solid;
  border-radius: 2px;

  padding-bottom: 10px;
  margin-right: 10px;
}

.bton {
  width: 34px;
  height: 34px;

  border-radius: 6px;
  border: 1px;
  margin-right: 6px;
  /* gap: 5px; */
}

.button_module {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.sort_button {
  width: 24px;
  height: 24px;
  border-radius: 2px;
  border: 1px;
  margin-right: 2px;
  background: none;
}
