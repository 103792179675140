* {
  font-family: "Inter", sans-serif;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  --common-black-color: #0d141c;
}
.common-section {
  margin-top: 104px;
  margin-bottom: 24px;
  min-height: 78vh;
}
.common-title {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  letter-spacing: -0.8px;
  color: var(--common-black-color);
  margin-bottom: 24px;
}
.table-heading {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: var(--common-black-color);
}
table {
  text-align: center;
}
.table td {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: var(--common-black-color);
  padding: 20px 0;
}
.table > :not(:last-child) > :last-child > * {
  border-bottom-color: #e8edf5;
}

.table th {
  padding: 20px 0;
}
.footer-title {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #4a709c;
}
.btn:focus {
  outline: none;
  box-shadow: none;
}
.common-sub-title {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #121417;
}
.common-blue-button,
.common-blue-button:hover {
  background: #1a80e6;
  border-radius: 12px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.21px;
  color: #f7fafc;
  padding: 4px 16px;
  text-align: center;
  padding: 10px 16px;
}

.common-gray-button,
.common-gray-button:hover {
  background: #e8edf2;
  border-radius: 12px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.21px;
  color: var(--common-black-color);
  padding: 10px 16px;
}
.centerd-section .title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #4f7396;
}
.centerd-section .sub-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #0d141c;
}
.centerd-section .container {
  position: relative;
}
.centerd-section-details {
  position: absolute;
  left: 25%;
  width: 60%;
}
.common-description {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #121417;
}
.no-data-msg {
  font-size: 26px !important;
  margin-top: 24px;
  margin-bottom: 24px;
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
}
.lab-setup-images {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
.lab-setup-images img {
  width: 200px;
  height: 300px;
}
