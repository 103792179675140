.navbar-section {
  display: flex;
  justify-content: space-between;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 14px;
  padding-bottom: 14px;
  border-bottom: 1px solid #e5e8eb;
  align-items: center;
}
.navbar-section .navs-links {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #0d141c;
  text-decoration: none;
}

.nav-phn-logo{
  width: 70px;
}
.nav-logo{
  padding: 10px;

}
.navs-links.active {
  font-weight: bold;
  color: #007bff;
}
.profile-list {
  position: absolute;
  top: 87%;
  right: 36px;
  /* background-color: #e5e8eb; */
  padding: 10px 20px;
  border-radius: 8px;
  max-width: 300px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.nav-logout-no-btn{
  width: 14%;
  border-radius: 12px;
}

.logout-modal .modal-header{
  padding: 0rem 1rem;
  font-weight: 700;
  border-bottom:none ;
}

.logout-modal .modal-title{
  font-family: inter;
  font-size: 25px;
  font-weight: 600;
}

.nav-modal-body-text{
  font-family: inter;
  font-weight: 500;
}

.nav-profile-img{
  width:24px;
  height: auto;
}

.nav-profile-text{
  font-size:16px;
  margin-left: 10px;
  margin-bottom: 0;
}

.nav-profile-list-one{
  padding-bottom: 0;
}