
.profile-img-wrapper{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

}
.profile-img{
    width: 50%;
    /* height: 150; */
    /* border-radius: 50%; */
    overflow: "hidden";

}

.profile-title {
  font-family: inter;
  font-size: 32px;
  line-height: 1.25;
  color: #0d121c;
  font-weight: 700;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

.profile-btn-wrapper{
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }

  .profile-btn-wrapper, button{
    font-family: inter;
  }

  .profile-delete-btn{
    font-family: inter;
    border-radius: 12px;
    font-style: normal;
    border-radius: 12px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.21px;
    /* color: #f7fafc; */
    padding: 4px 16px;
    text-align: center;
    padding: 10px 16px;
  }