.reset-pass {
  background: lightgray -495.872px 0px / 176.628% 100% no-repeat, #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.resetpass-title {
  font-family: inter;
  font-size: 38px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 20px;
  /* text-align: left; */
}

.resetPassInput {
  border-radius: 10px;
  font-family: inter;
}

.resetpassText {
  font-family: inter;
}
.resetpass-inputText {
  font-family: inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 24px;
  margin-bottom: 6px;
  text-align: left;
}

.backtoLogin-btn {
  line-height: 24px;
  letter-spacing: 0em;

  text-align: center;
  font-size: 20px;
  font-style: normal;
  /* font-weight: 400; */
  line-height: normal;
  border-radius: 10px;
  background: #e4e7eb;
  width: 100%;
  margin-top: 24px;
}
