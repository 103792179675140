.login-page {
  /* width: 1100px; */
  width: 570px;
  background: lightgray -495.872px 0px / 176.628% 100% no-repeat, #d9d9d9;
  margin: auto;
  padding: auto;
  justify-content: center;
  align-items: center;
  padding: 100px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* height: 120vh; */
}
.login-form {
  position: relative;
}
.loginPage-title {
  font-size: 38px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 20px;
  text-align: left;
}

.login_show_pass_icon {
  cursor: pointer;
  width: 24px;
  right: 2%;
  position: absolute;
  top: 55%;
}

.labelText {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 24px;
  margin-bottom: 6px;
  text-align: left;
}

.login-btn {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 10px;
  background: #1a80e5;
  margin-top: 24px;
}

.login-btn:hover {
  color: #fff;
}

.create-acc-text {
  margin-top: 10px;
  color: #637587;
}

.create-acc-btn {
  line-height: 24px;
  letter-spacing: 0em;

  text-align: center;
  font-size: 20px;
  font-style: normal;
  /* font-weight: 400; */
  line-height: normal;
  border-radius: 16px;
  background: #e4e7eb;
  width: 100%;
  margin-top: 24px;
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .login-page {
    width: 40rem;
    padding: 20px 0;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .login-page {
    /* width: 1000px; */
    padding: 20px 0;
  }
}

@media (min-width: 1400px) {
  .login-page {
    height: 100vh;
  }
}
