
.installerList-section .modal-title{
    font-weight: 700;
}


.installerList-modal-text {
    font-family: inter;
    font-size: 16px;
    line-height: 1.5;
    color: #0d121c;
    font-weight: bold;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 5px;
  }

  .installerList-modal-input {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-radius: 12px;
    padding: 15px 15px;
    border: none;
    background-color: #f7fafc;
    /* width: 480px; */
    width: 100%;
    /* height: 56px; */
  }

  .edit-icon-wrapper{
    /* margin-right: 10px; */
    cursor: pointer;
  }

  .delete-icon-wrapper{
    /* margin-right: 10px; */
    cursor: pointer;
  }