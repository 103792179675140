.input-group-text {
  background: #e8edf5;
  border: none;
  padding: 10px;
}
input[type="search"] {
  background: #e8edf5;
  border: none;
}
input[type="search"]:focus {
  background: #e8edf5;
  border: none;
  outline: none;
  box-shadow: none;
}

.order-table {
  border: 1px solid #e8edf5;
  padding: 14px;
  border-radius: 14px;
}

.approved-btn .btn {
  background-color: #0d7df2;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  float: right;
  text-align: center;
  color: #f7fafc;
  margin-top: 24px;
  border-radius: 12px;
  padding: 8px 20px;
}
